import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"
import BackgroundImage from "gatsby-background-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Tore from "../queries/tore"

import ServiceAndProcedure from "../components/serviceAndProcedure"

const Index = () => {
  const { sliderImage } = useStaticQuery(
    graphql`
      query {
        sliderImage: file(relativePath: { eq: "slider/header_zaun.jpg" }) {
          childImageSharp {
            gatsbyImageData(
              quality: 85
              placeholder: BLURRED
              formats: [AUTO, WEBP, JPG]
            )
          }
        }
      }
    `
  )
  const image = getImage(sliderImage)
  const bgImage = convertToBgImage(image)

  return (
    <Layout spacing="true">
      <Seo
        title="Tore und Zäune"
        description="Modern oder rustikal, funktionell oder auffällig, passend zum Stil Ihres Hauses."
      />

      <div className="flex w-full h-screen">
        <BackgroundImage
          {...bgImage}
          style={{ width: "100%" }}
          className="h-full w-full flex items-center"
        >
          <div className="container max-w-screen-lg xl:max-w-screen-xl mx-auto px-6 text-white mt-48 md:mt-72">
            <h1 className="text-4xl md:text-5xl font-bold mb-1.5">
              Tore & Zäune
            </h1>
            <p className="max-w-4xl text-xl mt-1">
              Modern oder rustikal, funktionell oder auffällig, passend zum Stil
              Ihres Hauses.
            </p>
          </div>
        </BackgroundImage>
      </div>
      <div className="container max-w-screen-lg xl:max-w-screen-xl mx-auto px-6 bg-white">
        <div className="grid gap-5 grid-cols-1 lg:grid-cols-2">
          <div className="flex items-center justify-center lg:order-last">
            <img
              src="/zeichnungen/zaun.svg"
              className="block h-56"
              alt="Technische Zeichnung eines Zauns"
            />
          </div>
          <div className="flex items-center">
            <p>
              Sie finden bei uns maßgefertigte Tore und Zäune für Ihr
              Grundstück. Diese führen wir in freiwählbaren Modellen, Höhen,
              Breiten und Ausführungen. Dabei ist unser Sortiment so individuell
              wie die Vorstellungen unserer Kunden. Varianten von Toren für
              Metallzäune reichen von kleinen Personentoren bis hin zu großen
              Fahrzeugtoren.
            </p>
          </div>
        </div>
      </div>
      <ServiceAndProcedure />
      <div className="pb-16">
        <h2 className="w-full text-4xl md:text-5xl text-center mb-6 text-gray-800">
          Galerie
        </h2>
        <Tore />
      </div>
    </Layout>
  )
}

export default Index
